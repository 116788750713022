var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom"},[_vm._m(0),_c('div',{staticClass:"card-body"},[[_c('div',{},[_c('v-data-table',{staticClass:"elevation-1 table-bordered table-striped",attrs:{"dense":"","headers":_vm.getCompanyInfo.id==1 ? _vm.headers2 : _vm.headers,"items":_vm.tickets,"page":_vm.page,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"footer-props":{
                      showFirstLastPage: true,
                      firstIcon: 'fa fa-angle-double-left',
                      lastIcon: 'fa fa-angle-double-right',
                      prevIcon: 'fa fa-angle-left',
                      nextIcon: 'fa fa-angle-right',
                      itemsPerPageText: 'Ticket per page: '
                    }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.getCompanyInfo.id==1)?{key:"item.paribahan",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.company_name))])]}}:null,{key:"item.info",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-left mb-1"},[_c('span',[_vm._v(_vm._s(item.boarding_station)+" -> "+_vm._s(item.dropping_station))])]),_c('b-badge',{staticClass:"w-100 mb-1",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(item.coach_number)+" ")]),(item.vehicle_type_id==1)?_c('b-badge',{staticClass:"w-100 mb-1",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(item.vehicle_type_name)+" ")]):_vm._e(),(item.vehicle_type_id==2)?_c('b-badge',{staticClass:"w-100 mb-1",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(item.vehicle_type_name)+" ")]):_vm._e()]}},{key:"item.passenger",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.passenger_name))]),_c('div',{staticClass:"text-black-50"},[_vm._v(_vm._s(item.passenger_mobile))])]}},{key:"item.seats",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.seat_numbers))])]}},{key:"item.fare",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.grand_total))])]}},{key:"item.booking",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.booked_at ? _vm.moment(item.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A') : _vm.moment(item.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A'))+" ")]}},{key:"item.journey",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.moment(item.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')))])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(item.status=='booked')?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status=='issued')?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"success"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status=='cancelled' && item.issued_at==null)?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"danger"}},[_vm._v("Expired")]):_vm._e(),(item.status=='cancelled' && item.issued_at!=null)?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"info"}},[_vm._v(_vm._s(item.status))]):_vm._e(),_c('br'),(item.status=='booked')?_c('small',[_vm._v(_vm._s(_vm.moment(item.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')))]):_vm._e(),(item.status=='issued')?_c('small',[_vm._v(_vm._s(_vm.moment(item.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')))]):_vm._e(),(item.status=='cancelled')?_c('small',[_vm._v(_vm._s(_vm.moment(item.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')))]):_vm._e()]}},{key:"item.view",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'ticket-details',params:{id:item.id}}}},[_c('v-chip',{staticClass:"bg-blue",staticStyle:{"cursor":"pointer"}},[_vm._v("View")])],1)]}}],null,true)})],1)]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"card-icon"},[_c('i',{staticClass:"fa fa-ticket-alt text-aqua"})]),_c('h3',{staticClass:"card-label text-uppercase"},[_vm._v("My Tickets")])])])}]

export { render, staticRenderFns }